@import "@/assets/styles/variables.scss";





























































.actionsList {
  .accordionItem {
    min-width: 620px;
    max-width: 620px;
    border-bottom: 1px solid black;
    margin: 0 auto;
  }

  .accordionItem_title {
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    color: black;
    padding: 25px 0;
    line-height: .90;
  }

  .accordionItem_body {
    padding: 0 0px 25px;
  }
}

.admin {
  min-height: 50vh;

  > .actionsList {
    margin-bottom: 50px;
  }

  h1 {
    transform: translateY(-84px);
    margin: 0;
  }

  h2 {
    margin-bottom: 30px;
  }

  ul {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
  }
}

#globalLoader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: white;
  display: flex;

  .loader {
    margin: auto;
  }
}
