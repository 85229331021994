@import "@/assets/styles/variables.scss";















































































































































































































.admin-talents-filters {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: row;
}
#modalTagsList .vs__dropdown-menu {
  position: static !important;
  display: flex !important;

  li {

  }
}

.tagsDone a {
  background-color: $pale-green;
}
.admin-tags-filters-letters {
  .current {
    background-color: $pale-green;
  }
}
