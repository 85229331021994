@import "@/assets/styles/variables.scss";
















































































































































































































.admin-tags {
  .table {
    width: 100%;

    tr.tagsDone {
      background-color: rgba(0, 255, 0, .3);

      &:hover {
        background-color: rgba(0, 255, 0, .4);
      }
    }

    td {
      vertical-align: middle;

      ul {
        display: flex;
        justify-content: flex-start;
      }
    }

    .tags {
      ul {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;

        li {
          margin: 5px 10px 5px 0;
          border: 2px solid black;
          border-radius: 30px;
          background-color: #fff;
          color: #000;
          font-weight: 500;
          padding: 5px 10px;

          &:empty {
            display: none;
          }
        }
      }

      p {
        margin: 0;
      }
    }
  }

  $offset: 3px;

  .admin-tags-filters-letters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid black;
    margin: 0px 0 40px;
    overflow: hidden;
    padding-top: $offset;

    li {
      cursor: pointer;
      border: 1px solid black;
      border-bottom: 0;
      border-radius: 5px 5px 0 0;
      padding: 2px 6px 4px 6px;
      margin: 0 3px $offset * -1;
      flex: 1;
      text-align: center;
      user-select: none;
      transition: transform 250ms;

      &.current {
        background-color: rgba(0, 0, 0, .15);
        transform: translateY($offset * -1);
      }
    }
  }
  .cellProfil {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      margin-right: 1em;
    }
  }
}
#modalTagsList .vs__dropdown-menu {
  position: static !important;
  display: flex !important;

  li {

  }
}


