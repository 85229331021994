@import "@/assets/styles/variables.scss";





































































.accordionItem {
  -webkit-appearance: none;

  .accordionItem_title {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    user-select: none;

    svg {
      transition: transform 350ms;
      will-change: transform;
    }
  }
}
.accordionItem-open {
  .accordionItem_title {
    svg {
      transform: rotate(180deg);
    }
  }
}
